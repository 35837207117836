let advertTracking = (() => {

    let adverts;

    function init() {
        if (bindUI())
            watchUI();
    }

    function bindUI() {
        adverts = document.querySelectorAll('.kca-linked');
        return !!(adverts);
    }

    function watchUI() {
        adverts.forEach((advert) => {
            advert.addEventListener('click', () => {
                log(advert.dataset.id);
            })
        })
    }

    function log(id) {
        fetch(wpApiSettings.root + 'kca/v1/log', {
            method: 'POST',
            body: JSON.stringify({"id": id}),
            headers : {
                'X-WP-Header' : wpApiSettings.nonce,
                'Content-Type': 'application/json'
            }
        })
    }

    init();

    return self;

})();
